<template>
  <div id="projects-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <h3>Neues Projekt</h3>

    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-5">
        <h3 class="text-center">Erstelle deine Firma oder dein Projekt.</h3>
        <p class="text-center">
          Damit erhältst du zugeschnittene Informationen und Zugriff auf alle Funktionen der Toolbox.
        </p>

        <hr>

        <div class="form-group">
          <label for="projectName">Name des Projekts:</label>
          <input id="projectName" v-model="project.profile.projectName" class="form-control" placeholder="Name des projekts" required type="text">
        </div>

        <div class="form-group">
          <label for="branch">Branche deines Projekts:</label>
          <select id="branch" v-model="project.profile.branch" class="form-control" name="branch">
            <option value="">Branche wählen</option>
            <option v-for="(branch, index) in branches" :key="index" :value="branch">{{ branch }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="rootUrl">URL der Website des Projekts:</label>
          <input id="rootUrl" v-model="project.profile.rootUrl" class="form-control" placeholder="https://dein-projekt.de" type="text">
          <p class="small">
            Wenn du noch keine Domain hast, keine Sorge, du kannst sie später ergänzen.
          </p>
        </div>

        <div class="form-group">
          <label for="color">Deine Corporate-Identity-Farbe</label>
          <input id="color" v-model="project.settings.themeColor" class="form-control" placeholder="Farbe ausählen" type="color">
        </div>

        <div class="form-group">
          <label for="rootUrl">Weitere Team-Mates per E-Mail einladen:</label>
          <input v-model="inviteEmails" class="form-control mb-3" placeholder="Mehrere E-Mails mit Komma trennen" type="text">
          <p class="small text-muted">
            Aktuell werden noch keine Einladungs-Mails versandt. Bitte benachrichtige deine Team-Mitglieder manuell. <br>
            Du kannst in den Projekteinstellungen auch später noch neue Mitglieder einladen.
          </p>
        </div>

        <button class="btn btn-primary btn-block mt-3 mb-5" @click="createProject">Neues Projekt starten</button>

      </div>
    </div>

  </div>
</template>

<script>

const memberTemplate = require('../../backend/templates/member.template');
export default {
  name: 'ProjectsAdd',
  components: {},
  data() {
    return {
      inviteEmails: "",
    }
  },
  computed: {
    branches() {
      return this.$store.state.practiceFilters.industry;
    }
  },
  methods: {
    createProject() {
      let user = JSON.parse(JSON.stringify(this.user));
      delete user._projectIds;

      let member = Object.assign(memberTemplate, user);
      member._userId = user._id;
      member.roles = ["creator", "admin"];
      member.memberSince = new Date().toISOString();
      member.settings.activeTheoryId = "";

      this.project.members = [member].concat(this.inviteEmails.split(",").map(e => { return { email: e.trim() } }))
      this.project.profile.contactEmail = user.email;

      setTimeout(() => {
        this.$store.dispatch('project/createProject')
            .then(res => {
              console.debug("Project created", res);
              this.$store.dispatch('project/switchProject', res.project._id)
                  .then(() => {
                    this.$router.push("/scraper");
                  });
            });
      }, 300);
    }
  },
  beforeMount() {
    this.$store.commit('project/removeProjectData');
    this.$store.commit('project/removeScrapeData');
  }
}
</script>
